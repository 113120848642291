<!-- 文件预览详情 -->
<template>
  <div v-if="flieList.length">
    <div v-for="(item, i) in JSON.parse(flieList)" :key="i" class="flie-list">
      <i class="el-icon-document"></i>
      <a class="page-link ml5">
        <el-tooltip
          class="item"
          effect="dark"
          :content="item.name"
          placement="top-start"
        >
          <span class="ell">
            <router-link
              :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
              target="_blank"
            >
              {{ item.name }}
            </router-link>
          </span>
        </el-tooltip>
      </a>
      <span class="size ml10">
        {{ utils.calculateFileSize(item.size) }}
      </span>
      <span class="ml10">{{ item.time }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    flieList: {
      type: String,
      default: '',
    },
  },
}
</script>
