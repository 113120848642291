export default {
  // 订单明细列表
  orderColumns: [
    {
      label: '加工单号',
      prop: 'processOrderCode',
      isShow: true,
      checkable: false,
      width: '150',
    },
    {
      label: '销售单号',
      prop: 'salesOrderCode',
      isShow: true,
      checkable: false,
      width: '180',
    },
    {
      label: '客户代表',
      prop: 'businessUserName',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '产品编码',
      prop: 'skus',
      isShow: true,
      checkable: false,
      width: '',
    },
    {
      label: '要求加工数量',
      prop: 'totalProcessNum',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '总加工费',
      prop: 'totalProcessFee',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '备注',
      prop: 'remark',
      isShow: true,
      checkable: true,
      width: '300',
    },
    {
      label: '状态',
      prop: 'status',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '创建人',
      prop: 'creator',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '创建时间',
      prop: 'createTime',
      isShow: true,
      checkable: true,
      width: '180',
    },
  ],

  // 产品明细列表
  productColumns: [
    {
      label: '加工单号',
      prop: 'processOrderCode',
      isShow: true,
      checkable: false,
      width: '150',
    },
    {
      label: '销售单号',
      prop: 'salesOrderCode',
      isShow: true,
      checkable: false,
      width: '150',
    },
    {
      label: '产品编码',
      prop: 'skuId',
      isShow: true,
      checkable: false,
      width: '',
    },
    {
      label: '产品名称',
      prop: 'productCn',
      isShow: true,
      checkable: false,
      width: '',
    },
    {
      label: '供应商',
      prop: 'supplierName',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '产品颜色',
      prop: 'productSpecs',
      isShow: true,
      checkable: true,
      width: '',
    },
    // {
    //   label: '产品材质',
    //   prop: 'productMaterial',
    //   isShow: true,
    //   checkable: true,
    //   width: '',
    // },
    {
      label: '要求加工数量',
      prop: 'processNum',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '产品尺寸',
      prop: 'productSize',
      isShow: true,
      checkable: true,
      width: '',
    },

    {
      label: '效果图',
      prop: 'productImage',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '印刷方式',
      prop: 'printWay',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '印刷颜色',
      prop: 'printColor',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '印刷尺寸',
      prop: 'printSize',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '印刷描述',
      prop: 'printDesc',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '包装要求',
      prop: 'packaging',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '内部备注',
      prop: 'innerRemark',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '备注',
      prop: 'remark',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '运输类型',
      prop: 'shippingMethodType',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '预计生产时间',
      prop: 'productionTime',
      isShow: true,
      checkable: true,
      width: 180,
    },
    {
      label: '期望交期',
      prop: 'expectDeliveryDate',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '是否加急',
      prop: 'urgent',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '用料数量',
      prop: 'useNum',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '领料进度',
      prop: 'matterStatus',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '加工状态',
      prop: 'pickingStatus',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '验货状态',
      prop: 'inspectionStatus',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '出运状态',
      prop: 'shipFlag',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '创建时间',
      prop: 'createTime',
      isShow: true,
      checkable: true,
      width: '180',
    },
    {
      label: '创建人',
      prop: 'creator',
      isShow: true,
      checkable: true,
      width: '',
    },
    {
      label: '完工时间',
      prop: 'completeTime',
      isShow: true,
      checkable: true,
      width: '180',
    },
  ],

  // 状态
  statusList: [
    {
      label: '全部(不含作废)',
      value: null,
    },
    {
      label: '未加工',
      value: 1,
    },
    {
      label: '加工中',
      value: 2,
    },
    {
      label: '已完工',
      value: 3,
    },
    {
      label: '异常完工',
      value: 4,
    },
    {
      label: '已作废',
      value: 5,
    },
  ],

  // 印刷方式
  printingWaySelect: [
    {
      label: '丝印',
      value: '丝印',
    },
    {
      label: '烫画',
      value: '烫画',
    },
    {
      label: '丝印+烫画',
      value: '丝印+烫画',
    },
    {
      label: '其他',
      value: '其他',
    },
    {
      label: '无需印刷',
      value: '无需印刷',
    },
    {
      label: '刺绣',
      value: '刺绣',
    },
  ],

  // 是否
  whetherList: [
    {
      label: '是',
      value: 1,
    },
    {
      label: '否',
      value: 0,
    },
  ],

  // 状态
  statusTypeName(n) {
    const dicts = {
      1: '正常',
      2: '已作废',
    }

    return dicts[n]
  },

  // 加工状态类型转化
  pickingStatusTypeName(n) {
    const dicts = {
      1: '未加工',
      2: '加工中',
      3: '已完工',
      4: '异常完工',
      5: '作废',
    }

    return dicts[n]
  },

  // 验货状态类型转化
  inspectionStatusTypeName(n) {
    const dicts = {
      1: '未安排验货',
      2: '待验货',
      3: '验货完成',
      4: '验货中',
      5: '验货不合格',
    }

    return dicts[n]
  },

  // 领料状态类型转化
  matterStatusTypeName(n) {
    const dicts = {
      1: '未领料',
      2: '部分领料',
      3: '全部领料',
    }

    return dicts[n]
  },

  // 入库状态类型转化
  stockStatusTypeName(n) {
    const dicts = {
      1: '未入库',
      2: '部分入库',
      3: '全部入库',
    }

    return dicts[n]
  },
  // 出运状态
  shipFlagTypeName(n) {
    const dicts = {
      0: '未出运',
      1: '已出运',
    }
    return dicts[n] || '--'
  },

  //运输类型
  shippingMethodTypeName(n) {
    const dicts = {
      1: '空运',
      2: '海运',
      3: '快递',
    }
    return dicts[n] || '--'
  },
}
